import React from "react"
import PropTypes from "prop-types"

import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import "./headerStyles.scss"
import MyNavbar from "../Navbar"
import Logo from '../../images/practicealpha-nav-logo-dog.png'

const Header = ({ siteTitle, siteDescription }) => {


  return (
    <Navbar bg="dark" variant="dark" sticky="top" >
      <Container fluid>
        <Navbar.Brand href="/docs" className="siteTitle">
          <img src={Logo} alt="logo" className="logo" />
          { siteTitle }
        </Navbar.Brand>
        <Nav>
          <MyNavbar/>
        </Nav>
      </Container>
    </Navbar>

  )
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  siteDescription: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``
}

export default Header
