import { graphql, useStaticQuery } from "gatsby";

export const useCommonData = () => {

  return useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
}