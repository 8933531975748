import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import "./documentsNavStyles.scss"
import {getDocumentMapMdx, getDocumentMapRemark} from "./documentNavUtils";

const DocumentsNav = () => {

//  allFile(filter: {ext: {eq: ".md"}, relativeDirectory: {regex: "docs/"}}) {

    const data = useStaticQuery(graphql`
    query MyQuery {
      mdx: allFile(filter: {ext: {in: [ ".mdx" ]}, relativeDirectory: {regex: "docs/"}}) {
        edges {
          node {
            relativePath
            childMdx {
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
      
    }
  `)


  // const { edges } = data.allFile
  // const { edges } = data.md

  // const navMap = getDocumentMapRemark(data.md.edges)

  const navMapMdx = getDocumentMapMdx(data.mdx.edges)

  const linkArray = [...navMapMdx].sort()
  // console.log(`navMap:`)
  // console.log(navMap)

  // {[...navMap].sort().map((entry, id) => {

  return (
    <ul className="document-nav">

      {linkArray.map((entry, id) => {
        return (
          <li key={id}>
            <h3 className="document-heading" >{entry[0]}</h3>
              {entry[1].map((item, key) => {
                return (
                  <li className="document-link" key={key}>
                    <Link to={item.url} key={item.url} >
                      {item.label}
                    </Link>
                  </li>
                )
              })}
              
            <hr/>
          </li>
        )
      })}

    </ul>
  )
}

export default DocumentsNav

