import React from "react"
import { Link } from "gatsby"
import LoginOutBtn from "../auth/LoginOutBtn"
import { links } from "../../data/navbarLinks";
import { isAuthenticated, getProfile } from "../../utils/auth"
import "./navbarStyles.scss"

const Navbar = () => {

  const user = getProfile()

  return (
    <ul className="navbar-main">

      { links && links.map( (item, key) => {
        if( item.isProtected && !isAuthenticated() )
          return null
        else
          return (
          <li key={ key }>
            <Link to={ item.url }>
              { item.label }
            </Link>
          </li>
      )})}
      {/*<span className="userName">{[user.name]}</span>*/}
      <LoginOutBtn />
    </ul>
 )
}

export default Navbar