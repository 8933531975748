import capitalizeWords from "../../utils/textFormatter";
import {HTMLLink} from "../../objects/HTMLLink";


// TODO: Make this handle more than one sub-folder

//
export const getDocumentMapRemark = (edges) => {

  const navMap = new Map()
  for(const edge of edges) {

    const item = edge.node
    const { childMarkdownRemark } = item

    // console.log(item.relativePath)
    const pathArray = item.relativePath.split("/")

    if(pathArray.length > 3) {
      console.error("Markdown pages can only be nested in 1 folder")
      return null
    }

    const heading = capitalizeWords(pathArray.length > 2 ? pathArray[1] : "General")

    const link = new HTMLLink(childMarkdownRemark.frontmatter.title, childMarkdownRemark.fields.slug, false)

    // Load up our Map object
    if(navMap.has(heading)) {
      navMap.get(heading).push(link)
    } else {
      const array = []
      array.push(link)
      navMap.set(heading, array)
    }
  }

  return navMap
}

export const getDocumentMapMdx = (edges) => {

  const navMap = new Map()
  for(const edge of edges) {

    const item = edge.node
    const { childMdx } = item

    // console.log(item.relativePath)
    const pathArray = item.relativePath.split("/")

    if(pathArray.length > 3) {
      console.error("Markdown pages can only be nested in 1 folder")
      return null
    }

    const heading = capitalizeWords(pathArray.length > 2 ? pathArray[1] : "General")

    const link = new HTMLLink(childMdx.frontmatter.title, childMdx.fields.slug, false)

    // Load up our Map object
    if(navMap.has(heading)) {
      navMap.get(heading).push(link)
    } else {
      const array = []
      array.push(link)
      navMap.set(heading, array)
    }
  }

  return navMap
}