import React from "react"
import Button from "react-bootstrap/Button"
import { isAuthenticated, login, logout } from "../../utils/auth"

const variant = "outline-light"

const LoginOutBtn = () => {

  const buttonText = isAuthenticated() ? "Logout" : "Login"

  const handleClick = ( evt ) => {
    evt.preventDefault()

    if( isAuthenticated() )
      logout()
    else
      login()
  }

    return (
      <Button
        variant={ variant } size="sm"
        onClick={( evt ) => handleClick( evt )}
      >
        { buttonText }
      </Button>
    )
}

export default LoginOutBtn