const WORD_BREAK = "_"
const CAPITAL_BREAK = "-"


// Turns "cloud-sync_app" into "CloudSync App"
function capitalizeWords( text : string) : string {

    text = splitWords(text, CAPITAL_BREAK)
    text = splitWords(text, WORD_BREAK)

    return text
}

function splitWords( text : string, delimiter : string ) : string {

    if( text.includes(delimiter) ) {
        const textArray = text.split(delimiter)

        // console.log(`textArray:`)
        // console.log(textArray)

        const newArray = []
        for(const part of textArray) {
            newArray.push(part[0].toUpperCase() + part.substring(1))
        }
        // console.log(`newArray:`)
        // console.log(newArray)
        text = newArray.join("")

        switch (delimiter) {
            case WORD_BREAK:
                text = newArray.join(" ")
                break
            case CAPITAL_BREAK:
                text = newArray.join("")
                break;
        }

    }
    text = text[0].toUpperCase() + text.substring(1)

    return text
}

module.exports = capitalizeWords